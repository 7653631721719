jQuery(document).ready(function($){
  // form elements - jcf
  if (typeof jcf == 'object') {
		jcf.setOptions('Select', {
			fakeDropInBody: true,
	    maxVisibleItems: 5,
	    wrapNative: false,
	    wrapNativeOnMobile: true,
			useCustomScroll: false
		});

		jcf.replace($('.custom-select select'));
		jcf.replace($('[type="radio"]'));
		jcf.replace($('[type="checkbox"]'));
	}

  // modals
  if($.fn.magnificPopup) {
    $('a[href*="modal"]').magnificPopup({
      type:'inline',
      removalDelay: 500,
      fixedContentPos: true,
      showCloseBtn: false,
      mainClass: 'mfp-with-zoom'
    });
    $('.js-modal-close, .modal').on('click', function(){
      $.magnificPopup.close();
    })
    $('[data-gallery]').magnificPopup({
      type:'image',
      gallery: {
        enabled: true
      },
    })
  }

  if($('#map').length) {
    function initMap() {
      if (!tense_vars.map || tense_vars === undefined ) {
          return;
      }
      var myLatLng = {lat: parseFloat(tense_vars.map.lat), lng: parseFloat(tense_vars.map.lng)};
      var map = new google.maps.Map(document.getElementById('map'), {
        center: myLatLng,
        zoom: 10,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
      });
      var image = tense_vars.imgdir + 'marker.png';
      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        icon: image,
      });
    }
    initMap();
  }

  if($.fn.validate) {
		$.validator.setDefaults({
        highlight: function(element, errorClass, validClass) {
          var $element = $(element);
          $element.addClass(errorClass).removeClass(validClass);
          if ($element.attr('type') == 'checkbox' || $element.attr('type') == 'radio') {
            if ($element.attr('type') == 'checkbox') {
              $element.closest('.checkbox').addClass('error-checkbox');
            } else if ($element.attr('type') == 'radio') {
              $element.closest('.radio').addClass('error-radio');
            }
          } else if ($element.prop("tagName") == 'SELECT') {
            $element.closest('.select').addClass('error-select');
          } else if ($element.prop("tagName") == 'TEXTAREA') {
            $element.closest('.wysiwyg-editor').addClass('error-wysiwyg');
          }
        },
        unhighlight: function(element, errorClass, validClass) {
          var $element = $(element);
          $element.removeClass(errorClass).addClass(validClass);
          if ($element.attr('type') == 'checkbox' || $element.attr('type') == 'radio') {
            if ($element.attr('type') == 'checkbox') {
              $element.closest('.checkbox').removeClass('error-checkbox');
            } else if ($element.attr('type') == 'radio') {
              $element.closest('.radio').removeClass('error-radio');
            }
          } else if ($element.prop("tagName") == 'SELECT') {
            $element.closest('.select').removeClass('error-select');
          } else if ($element.prop("tagName") == 'TEXTAREA') {
            $element.closest('.wysiwyg-editor').removeClass('error-wysiwyg');
          }
        },
        errorPlacement: function(error, element) {},
        ignore: ':hidden:not([type="hidden"]):not(select)'
    });

		$('form.js-validate').each(function() {
			$(this).validate();
		});
	}

  // menu trigger
  $('.menu-toggle').on('click', function() {
    $('html').toggleClass('is-mobile-nav-open');
  });

  $('body').on('click touchstart', function(e) {
    if(!$(e.target).closest('.menu-toggle').length) {
      if(!$(e.target).closest('.header__main-nav').length) {
        $('html').removeClass('is-mobile-nav-open');
      }
    }
  })

  //
  // browser navigator
  //
  var $browser_name = navigator.userAgent;
  if ($browser_name.toLowerCase().indexOf('edge') > -1) {
    $('html').addClass('edge').removeClass('chrome');
  }
  if ($browser_name.toLowerCase().indexOf('trident') > -1) {
    $('html').addClass('ie ie-11');
  }
  if ($browser_name.toLowerCase().indexOf('msie 10.0') > -1) {
    $('html').addClass('ie-10').removeClass('ie-11');
  }
  if ($browser_name.toLowerCase().indexOf('msie 9.0') > -1) {
    $('html').addClass('ie-9').removeClass('ie-11');
  };


    $('.product-categories .current-cat').each(function() {
        $(this).closest('.current-cat-parent').addClass('active');
    });

    $('.product-categories .cat-parent > a').click(function(event) {
        event.preventDefault();
        var $parent = $(this).closest('.cat-parent');
        $parent.find('.children').slideToggle('fast');
        $parent.toggleClass('active');
        // $(this).unbind('click');
    });

    function tense_update_order_caption() {
        var caption = $('#place_order').text();
        $('#place_order').html('<span>' + caption + '</span>');
    }

    $(document.body).on('payment_method_selected updated_checkout', function() {
        tense_update_order_caption();
    });

    $(document.body).on('updated_checkout updated_shipping_method', function() {
        console.log('updated');
        if (typeof jcf == 'object') {
            jcf.setOptions('Select', {
                fakeDropInBody: true,
                maxVisibleItems: 5,
                wrapNative: false,
                wrapNativeOnMobile: true,
                useCustomScroll: false
            });

            jcf.replace($('.custom-select select'));
            jcf.replace($('[type="radio"]'));
            jcf.replace($('[type="checkbox"]'));
        }

        if ($('body').hasClass('woocommerce-checkout')) {
            tense_update_order_caption();
        }
    });

    $(document.body).on('click change', 'input[name="payment_method"], .wc_payment_method label', function() {
        setTimeout(tense_update_order_caption, 100);
    });

    tense_update_order_caption();
});
